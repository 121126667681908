<template>
  <div>
    <div class="text-center m-20" v-if="isLoading">
      <img src="@/assets/svg/loader.svg" alt="loading..." />
    </div>

    <div
      class="
        grid
        gap-y-2
        xl:gap-y-0
        gap-x-0
        xl:gap-x-2
        grid-cols-1
        xl:grid-cols-7
        mt-3
        px-3
      "
      v-if="!isLoading"
    >
      <!-- If Null -->
      <div
        class="border border-solid border-gray-200 rounded-lg flex flex-col"
        v-for="(day, i) in workingDays"
        :key="day + i"
      >
        <div
          id="header"
          class="
            rounded-t-lg
            text-xl text-black
            py-3
            px-1
            capitalize
            font-EffraM
            text-center
          "
          style="background: #f3f3f3"
        >
          {{ moment().weekdays(day.day) }}
        </div>

        <div
          id="content"
          class="
            flex-grow flex flex-col
            gap-y-3
            py-5
            px-3
            max-h-400
            overflow-y-scroll
            scrolling-touch
            scroll-box
          "
          :class="!day.slots.length ? 'items-center justify-center' : ''"
          style="background: #f1f1f1"
        >
          <img src="/svg/bell-off.svg" v-if="!day.slots.length" alt="no data" />

          <!-- New Desing Slots -->
          <div
            class="px-3 py-2 bg-white rounded relative group"
            v-for="(hour, index) in day.slots"
            :key="index"
          >
            <!-- List Categorys -->
            <ul
              class="list-none m-0 p-0 cursor-pointer"
              @click="onClickSchedule(hour, day)"
            >
              <li>
                <div class="flex items-center">
                  <div class="bg-gray-700 rounded-full h-2 w-2"></div>
                  <div class="flex-1 ml-4 font-EffraR text-base py-2">
                    {{ hour.from }}
                  </div>
                </div>
                <div
                  class="border-0 border-l-2 border-solid border-gray-200 pl-2"
                  style="margin-left: 1.5px"
                >
                  <span
                    v-for="(color, i) in getCategories(hour)"
                    :key="i"
                    :style="`border-color: ${color.color}3b;`"
                    class="
                      rounded-lg
                      w-auto
                      px-2
                      py-1
                      mb-1
                      text-center
                      border-solid border-2
                      flex flex-wrap
                    "
                  >
                    <label
                      class="font-EffraR text-sm mff:text-base truncate"
                      :style="`color: ${color.color};`"
                    >
                      {{ color.title }}
                    </label>
                  </span>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <div class="bg-gray-700 rounded-full h-2 w-2"></div>
                  <div class="flex-1 ml-4 font-EffraR text-base py-2">
                    {{ hour.to }}
                  </div>
                </div>
              </li>
            </ul>
            <!-- Actions -->
            <div
              class="
                hidden
                group-hover:flex
                items-center
                justify-center
                absolute
                z-0
                bg-red-100
                h-8
                w-8
                rounded-full
              "
              style="right: -8px; top: -10px"
            >
              <button
                class="border-0 bg-transparent cursor-pointer"
                @click="onDelete(hour._id, day.day)"
                alt="Supprimer"
                aria-label="Supprimer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#e14b57"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 6h18M8 6V4c0-.53.21-1.04.586-1.414C8.96 2.21 9.47 2 10 2h4c.53 0 1.04.21 1.414.586C15.79 2.96 16 3.47 16 4v2m3 0v14c0 .53-.21 1.04-.586 1.414C18.04 21.79 17.53 22 17 22H7c-.53 0-1.04-.21-1.414-.586C5.21 21.04 5 20.53 5 20V6h14zM10 11v6M14 11v6"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div
          id="footer"
          class="rounded-b-lg flex flex-col py-5"
          style="background: #f3f3f3"
        >
          <button
            class="
              w-full
              bg-transparent
              border-0
              text-center
              flex
              items-center
              justify-center
              gap-x-2
              font-EffraR
              text-lg
              cursor-pointer
            "
            @click="onModalCreateSchedule(day)"
            aria-label="Ajouter horaire"
          >
            <img src="/svg/close.svg" alt="Ajouter horaire" />
            Ajouter horaire
          </button>
        </div>
      </div>
    </div>

    <!-- Create & Update Schedule -->
    <modal
      :is-active="modalCreate"
      :exit-modal="
        () => {
          this.modalCreate = false;
        }
      "
      :width="400"
    >
      <form
        v-on:submit.prevent="form._id ? onUpdate() : onCreate()"
        v-if="modalCreate"
      >
        <h5 class="font-EffraR px-5">Horaire</h5>

        <div class="flex flex-row gap-x-3 py-5 px-2">
          <div id="Date" class="flex flex-row items-start gap-x-2">
            <img src="/svg/calendar_sm.svg" />
            <div class="flex flex-col">
              <label for="le" class="text-gray-600 text-base font-EffraR"
                >De</label
              >
              <span
                class="
                  relative
                  text-blackedsidebar text-lg
                  font-EffraR
                  capitalize
                "
                >{{ form.from || "--" }}
                <vs-select
                  v-model="form.from"
                  class="inset-x-0 w-59 absolute top-0 opacity-0"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in generateHours()"
                  />
                </vs-select>
              </span>
            </div>
          </div>
          <div id="Date2" class="flex flex-row items-start gap-x-2">
            <img src="/svg/calendar_sm.svg" />
            <div class="flex flex-col">
              <label
                for="le"
                class="text-gray-600 text-base font-EffraR uppercase"
                >à</label
              >
              <span
                class="
                  relative
                  text-blackedsidebar text-lg
                  font-EffraR
                  capitalize
                "
                >{{ form.to || "--" }}
                <vs-select
                  v-model="form.to"
                  class="inset-x-0 w-59 absolute top-0 opacity-0"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in generateHours()"
                  /> </vs-select
              ></span>
            </div>
          </div>
        </div>
        <div
          class="flex px-2 py-5 border-0 border-b border-solid border-gray-300"
        >
          <div id="Days" class="flex flex-row items-start gap-x-2">
            <img src="/svg/calendar_sm.svg" />
            <div class="flex flex-col">
              <label for="le" class="text-gray-600 text-base font-EffraR"
                >Jour(s)</label
              >
              <button
                type="button"
                class="
                  dropdown:block
                  bg-transparent
                  font-EffraR
                  text-lg
                  border-0
                "
                :class="form._id ? 'cursor-not-allowed ' : 'cursor-text'"
                role="navigation"
                aria-haspopup="true"
              >
                <span>
                  <template v-if="form._id">
                    {{ capitalize()(moment().weekdays(form.dayId)) }}
                  </template>
                  <div
                    class="
                      flex
                      justify-between
                      items-center
                      gap-x-2
                      px-2
                      border-2 border-solid border-grayDok-sidebar
                      rounded
                    "
                    v-if="!form._id"
                  >
                    <div v-if="form.days.length">
                      <span v-for="(dayn, iday) in form.days" :key="iday">
                        <span class="font-EffraR">{{
                          capitalize()(moment().weekdays(dayn))
                        }}</span>
                        <span v-if="form.days.length - 1 !== iday"
                          >,
                        </span></span
                      >
                    </div>
                    <template v-if="!form.days.length">
                      Déterminez le(s) jour(s)
                    </template>

                    <i class="fas fa-angle-down"></i>
                  </div>
                </span>
                <ul
                  class="
                    hidden
                    absolute
                    shadow
                    bg-white
                    border border-solid border-gray-200
                    p-5
                  "
                  v-if="!form._id"
                  aria-label="submenu"
                >
                  <div
                    class="flex flex-row items-center gap-x-2 mb-4"
                    v-for="(day, i) in workingDays"
                    :key="day + i"
                  >
                    <input
                      type="checkbox"
                      class="
                        form-checkbox
                        text-xl
                        border-2 border-solid border-gray-400
                        text-dokBlue-ultra
                        focus:outline-none
                      "
                      v-model="form.days"
                      :id="`day-${day.day}`"
                      :value="day.day"
                    />
                    <label
                      class="capitalize font-EffraR text-base cursor-pointer"
                      :for="`day-${day.day}`"
                    >
                      {{ moment().weekdays(day.day) }}
                    </label>
                  </div>
                </ul>
              </button>
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-col
            px-2
            py-5
            border-0 border-b border-solid border-gray-300
          "
        >
          <h5 class="font-EffraR px-5">Motif(s)</h5>

          <div
            class="
              flex flex-col
              gap-y-2
              px-5
              py-3
              max-h-200
              overflow-y-scroll
              scroll
            "
          >
            <div
              class="flex flex-row gap-x-2 items-center"
              v-for="(cat, i) in categoriesDesctive"
              :key="i"
            >
              <input
                v-model="form.categories"
                :value="cat"
                :id="`motif-${i}`"
                type="checkbox"
                class="
                  form-checkbox
                  border-2 border-solid border-gray-500
                  text-xl
                  focus:outline-none focus:shadow-none
                  text-dokBlue-ultra
                "
              />
              <label
                :for="`motif-${i}`"
                class="
                  font-EffraR
                  text-base
                  border border-solid
                  px-2
                  py-1
                  rounded
                  cursor-pointer
                "
                :style="`border-color: ${cat.color}; color: ${cat.color}`"
                >{{ cat.title }}
              </label>
            </div>
          </div>
          <button
            class="
              text-left
              py-3
              flex
              items-center
              gap-x-2
              font-EffraR
              text-base
              bg-transparent
              border-0
              cursor-pointer
            "
            type="button"
            @click="newMotifClick"
            aria-label="Ajouter un motif"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="#111"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 16.5c4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5zM9 6v6M6 9h6"
              />
            </svg>
            Ajouter un motif
          </button>
        </div>
        <div class="container mx-auto px-2 flex flex-col gap-y-2">
          <button
            type="submit"
            class="
              bg-dokBlue-ultra
              h-12
              w-full
              text-white text-lg
              font-EffraM
              border-0
              rounded
              cursor-pointer
              flex
              items-center
              justify-center
              gap-x-2
            "
            @click="form._id ? onUpdate() : onCreate()"
            :aria-label="
              form._id ? 'Enregistrer les modifications' : 'Enregistrer'
            "
          >
            {{ form._id ? "Enregistrer les modifications" : "Enregistrer" }}
          </button>
          <button
            type="button"
            class="
              bg-transparent
              h-12
              w-full
              text-lg
              font-EffraM
              border-0
              rounded
              cursor-pointer
              flex
              items-center
              justify-center
              gap-x-2
            "
            style="color: #e14b57"
            v-if="form._id"
            @click="onDelete(form._id, form.dayId)"
            aria-label="Supprimer motif"
          >
            Supprimer Motif
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
import { capitalize, find, remove } from "lodash";
import moment from "moment";
const modal = () => import("@/views/global-components/dragModal.vue");

export default {
  props: {
    categories: Array,
  },
  data() {
    return {
      isLoading: false,
      modalCreate: false,
      form: {
        from: "12:00",
        to: "12:30",
        days: [],
      },
    };
  },
  computed: {
    workingDays: {
      get() {
        return this.$store.state.agenda.slots;
      },
      set(value) {},
    },
    categoriesDesctive() {
      return this.categories.filter((item) => {
        return !item.deactivated;
      });
    },
  },
  methods: {
    ...mapActions({
      getSlots: "agenda/GET_SLOTS",
      updateSlot: "agenda/UPDATE_SLOT",
    }),
    async newMotifClick() {
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "MOTIF",
          action: "NEW",
          data: {},
        },
      });
      EventBus.$emit("Click_new_motif");
    },
    moment() {
      return moment;
    },

    onUpdate() {
      event.preventDefault();
      if (
        !this.form.from ||
        !this.form.to ||
        !this.form.dayId ||
        !this.form.categories.length
      ) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Il y a un problème de traitement des données",
        });
      }

      let day = find(this.workingDays, { day: this.form.dayId });
      let slot = find(day.slots, { _id: this.form._id });

      slot.from = this.form.from;
      slot.to = this.form.to;
      slot.categories = this.form.categories;

      this.updateSlot({
        data: day,
        onData: ({ data, ok }) => {
          if (ok) {
            this.$performTracking({
              data: {
                id: this.$auth.getUserId(),
                route: "HORAIRE",
                action: "UPDATE",
                data: {},
              },
            });
            this.form = {};
            this.modalCreate = false;
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Mise à jour réussie",
            });
          }
        },
      });
    },

    onCreate() {
      event.preventDefault();
      if (
        !this.form.from ||
        !this.form.to ||
        !this.form.days.length ||
        !this.form.categories.length
      ) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Il y a un problème de traitement des données",
        });
      }
      this.form.days.forEach((dayIndex) => {
        let day = find(this.workingDays, { day: dayIndex });
        day.slots.push({
          from: this.form.from,
          to: this.form.to,
          categories: this.form.categories,
        });
        this.updateSlot({
          data: day,
          onData: ({ data, ok }) => {
            if (ok) {
              this.$performTracking({
                data: {
                  id: this.$auth.getUserId(),
                  route: "HORAIRE",
                  action: "CREATE",
                  data: {},
                },
              });
              this.form = {};
              this.modalCreate = false;
              EventBus.$emit("flashDok", {
                type: "success",
                message: "Création d'horaire réussie",
              });
            }
          },
        });
      });
    },

    onDelete(slotId, dayId) {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$vs.loading();
            let day = find(this.workingDays, { day: dayId });
            let slot = remove(day.slots, { _id: slotId });

            this.updateSlot({
              data: day,
              onData: ({ data, ok }) => {
                if (ok) {
                  this.$performTracking({
                    data: {
                      id: this.$auth.getUserId(),
                      route: "HORAIRE",
                      action: "DELETE",
                      data: {},
                    },
                  });
                  this.form = {};
                  this.modalCreate = false;
                  EventBus.$emit("flashDok", {
                    type: "success",
                    message: "Suppression réussie",
                  });
                  this.$vs.loading.close();
                  return this.$forceUpdate();
                }
              },
            });

            this.$vs.loading.close();
          }
        },
      });
    },
    generateHours() {
      const hours = [];
      new Array(17).fill().forEach((acc, index) => {
        hours.push(moment({ hour: index + 8 }).format("HH:mm"));
      });
      return hours;
    },
    getCategories(hour) {
      if (hour && hour.categories) {
        return hour.categories;
      }
    },
    onClickSchedule(data, day) {
      let dayx = find(this.workingDays, { _id: day._id });
      let slot = find(dayx.slots, { _id: data._id });

      this.form = slot;
      this.form.dayId = day.day;
      this.modalCreate = true;
    },
    onModalCreateSchedule(day) {
      this.form = {
        from: "12:00",
        to: "12:30",
        days: !day ? [] : [day.day],
        categories: [],
      };
      this.modalCreate = true;
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "HORAIRE",
          action: "NEW",
          data: {},
        },
      });
    },
    capitalize() {
      return capitalize;
    },
  },
  mounted() {
    this.isLoading = true;
    this.getSlots({
      onData: () => {
        this.isLoading = false;
      },
    });

    // Emite New Schedule
    EventBus.$on("Create_Schedule", () => this.onModalCreateSchedule(null));
  },
  components: { modal },
};
</script>

<style lang="scss" scoped>
.scroll-box::v-deep {
  &::-webkit-scrollbar {
    width: 6px;
    height: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-gray-500;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400;
  }
}
</style>
